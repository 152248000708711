frappe.ui.form.on('Issue', {
    customer(frm) {
        if (frm.doc.customer) {
            // actualizar_tipo_cliente(frm)
            frm.trigger("custom_contrato_seleccionado");
        }
    },
    refresh(frm) {
        if (frm.doc.customer) {
            // actualizar_tipo_cliente(frm)
            frm.trigger("custom_contrato_seleccionado");
        }

        if (frm.doc.name && (frm.doc.custom_tipo_de_solicitud == "Necesito desocupar" || frm.doc.custom_tipo_de_solicitud == "Proceso de restitución" || frm.doc.custom_tipo_de_solicitud == "Solicitudes Relacionadas con la Desocupación") && (frm.doc.custom_solicitud_de_desocupacion === undefined)) {
            actualizar_solicitud_desocupacion(frm)
        }

        if (frm.doc.custom_tipo_de_solicitud == "Necesito desocupar" || frm.doc.custom_tipo_de_solicitud == "Proceso de restitución" || frm.doc.custom_tipo_de_solicitud == "Solicitudes Relacionadas con la Desocupación") {
            boton_crear_solicitud_desocupacion(frm)
        }
    },
    issue_type(frm) {
        if (frm.doc.custom_tipo_de_solicitud == "Necesito desocupar" || frm.doc.custom_tipo_de_solicitud == "Proceso de restitución") {
            boton_crear_solicitud_desocupacion(frm)
        }
    },
    custom_contrato_seleccionado(frm) {
        frappe.call({
            method: 'frappe.client.get_list',
            args: {
                doctype: 'Contratos de Arrendamiento',
                fields: ['cliente_arrendatario', 'cliente_propietario'],
                filters: { name: frm.doc.contrato_seleccionado }
            },
            async: false,
            callback: function (r) {
                if (r.message) {
                    if (r.message[0].cliente_arrendatario == frm.doc.customer) {
                        frm.doc.custom_tipo_de_cliente = "Arrendatario";
                        frm.refresh_field('custom_tipo_de_cliente');
                    } else if (r.message[0].cliente_propietario == frm.doc.customer) {
                        frm.doc.custom_tipo_de_cliente = "Propietario";
                        frm.refresh_field('custom_tipo_de_cliente');
                    }
                }
            }
        });
    },
});

function boton_crear_solicitud_desocupacion(frm) {

    if (frm.doc.custom_contrato_seleccionado !== undefined && (frm.doc.custom_solicitud_de_desocupacion === undefined || frm.doc.custom_solicitud_de_desocupacion === null)) {
        frm.add_custom_button(
            __('Crear solicitud de desocupación'),
            function () {
                frappe.call({
                    method: 'inmobiliaria.solicitud_desocupacion.crear_solicitud_desocupacion',
                    args: {
                        data: JSON.stringify(frm.doc)
                    },
                    async: false,
                    callback: function (rp) {
                        if (rp) {
                            frm.doc.custom_tipo_de_cliente = rp.message.descupacion_solicitada_por
                            frm.refresh_field('custom_tipo_de_cliente');

                            frm.doc.custom_solicitud_de_desocupacion = rp.message.name;
                            frm.refresh_field('custom_solicitud_de_desocupacion');
                            if (rp.message.name) {
                                frappe.msgprint("Solicitud de desocupación creada correctamente")
                            }
                            if (rp.message.ya_existe) {
                                frappe.msgprint("Ya se ha creado una Solicitud de desocupación para esta incidencia")
                            }
                        }
                    }
                });
            },
        );

    }

}

function actualizar_solicitud_desocupacion(frm) {
    if (frm.doc.custom_solicitud_de_desocupacion !== undefined || frm.doc.custom_solicitud_de_desocupacion !== null) {
        frappe.call({
            method: 'frappe.client.get_list',
            args: {
                doctype: 'Solicitud de Desocupacion',
                fields: ['name'],
                filters: { incidencia: frm.doc.name }
            },
            async: false,
            callback: function (r) {
                if (r.message) {
                    frm.doc.custom_solicitud_de_desocupacion = r.message[0].name;
                    frm.refresh_field('custom_solicitud_de_desocupacion');
                }
            }
        });
    }
}

function actualizar_tipo_cliente(frm) {
    frappe.call({
        method: 'frappe.client.get_list',
        args: {
            doctype: 'Customer',
            fields: ['es_propietario', 'es_arrendatario'],
            filters: { name: frm.doc.customer }
        },
        async: false,
        callback: function (r) {
            if (r.message) {
                if (r.message[0].es_arrendatario == 1) {
                    frm.doc.custom_tipo_de_cliente = "Arrendatario";
                    frm.refresh_field('custom_tipo_de_cliente');
                }
                if (r.message[0].es_propietario == 1) {
                    frm.doc.custom_tipo_de_cliente = "Propietario";
                    frm.refresh_field('custom_tipo_de_cliente');
                }
            }
        }
    });
}